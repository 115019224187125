import PropTypes from "prop-types";
import React from "react";

import CGU from "../components/cgu/CGUDeprecated";
import i18nContext from "../components/i18n/i18n.context";
import Layout from "../components/layout/Layout";
import Page from "../components/page/Page";
import SEO from "../components/seo/Seo";

function CGUPage({ location }) {
  return (
    <i18nContext.Provider value={{ locale: "en" }}>
      <Layout>
        <SEO
          keywords={["Jawg", "JawgMaps", "GTC"]}
          location={location}
          metaDescription="On this page you will find Jawg's terms and conditions."
          title="Terms"
        />
        <Page>
          <CGU />{" "}
        </Page>
      </Layout>
    </i18nContext.Provider>
  );
}

CGUPage.propTypes = {
  location: PropTypes.object.isRequired, //eslint-disable-line react/forbid-prop-types
};

export default CGUPage;
